// src/ChatMessage.js

import React from 'react';

const ChatMessage = ({
                         exchange,
                         isEditMode,
                         editableQA,
                         handleEditQA,
                         handleQAChange,
                         handleSaveEditedQA,
                     }) => {
    const isEditing = isEditMode && exchange.question === editableQA.question;

    return (
        <div className="chat">
            {isEditing ? (
                <div>
          <textarea
              name="question"
              value={editableQA.question}
              onChange={handleQAChange}
          />
                    <textarea
                        name="answer"
                        value={editableQA.answer}
                        onChange={handleQAChange}
                    />
                    <button type="button" onClick={handleSaveEditedQA}>
                        Save
                    </button>
                </div>
            ) : (
                <div>
                    <div className="user">
                        <strong>You:</strong> {exchange.question}
                    </div>
                    <div className="filebaby">
                        <strong>JUSTIN:</strong>{' '}
                        {exchange.answer.split('\n').map((paragraph, i) => (
                            <p key={i}>{paragraph}</p>
                        ))}
                    </div>
                    <button type="button" onClick={() => handleEditQA(exchange)}>
                        Edit Response
                    </button>
                </div>
            )}
        </div>
    );
};

export default ChatMessage;
