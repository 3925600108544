import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import hero from './justin.png';
import './App.css'; // Import main styles
import './Chatbot.css'; // Import chat-specific styles
import Chatbot from './Chatbot';

function AppContent() {
    const isDevelopment = process.env.NODE_ENV === 'development';
    const userName = isDevelopment ? "karen@knowbots.org" : "Guest";

    const canvasRef = useRef(null);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const bufferLengthRef = useRef(null);
    const dataArrayRef = useRef(null);
    const hueRef = useRef(0); // Use a ref for hue to persist its value across renders
    const inactivityTimerRef = useRef(null); // Reference for inactivity timer

    const resetInactivityTimer = () => {
        // Clear any existing timer
        if (inactivityTimerRef.current) {
            clearTimeout(inactivityTimerRef.current);
        }

        // Set a new timer for 5 minutes (300,000 milliseconds)
        inactivityTimerRef.current = setTimeout(() => {
            window.location.reload(); // Refresh the page after 5 minutes of inactivity
        }, 300000); // 5 minutes
    };

    useEffect(() => {
        console.log("Running in Development Mode:", isDevelopment);

        // Function to initialize audio visualization
        const canvas = canvasRef.current;
        const canvasCtx = canvas.getContext('2d');

        function resizeCanvas() {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight / 2; // Make the canvas smaller for aesthetics
        }

        function initializeAudio() {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then(stream => {
                    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
                    const source = audioContextRef.current.createMediaStreamSource(stream);
                    analyserRef.current = audioContextRef.current.createAnalyser();
                    source.connect(analyserRef.current);
                    analyserRef.current.fftSize = 2048;
                    bufferLengthRef.current = analyserRef.current.frequencyBinCount;
                    dataArrayRef.current = new Uint8Array(bufferLengthRef.current);
                    draw();
                })
                .catch(err => {
                    console.error('Error accessing microphone:', err);
                });
        }

        function draw() {
            requestAnimationFrame(draw);
            analyserRef.current.getByteTimeDomainData(dataArrayRef.current);

            // Oscillate between black and grey for a subtle effect
            const lightness = 6 + 5 * Math.sin(hueRef.current); // Lightness between 0% and 20%
            hueRef.current += 0.01; // Very slow change for a subtle effect

            canvasCtx.fillStyle = `hsl(0, 0%, ${lightness}%)`; // Shades of grey
            canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

            // Thinner waveform lines
            canvasCtx.lineWidth = 2;

            // Set waveform color to a constant subtle color (e.g., light grey)
            canvasCtx.strokeStyle = 'rgb(17,255,0)';

            canvasCtx.beginPath();

            const sliceWidth = canvas.width * 1.0 / bufferLengthRef.current;
            let x = 0;

            for (let i = 0; i < bufferLengthRef.current; i++) {
                const v = dataArrayRef.current[i] / 128.0;
                const y = v * canvas.height / 2;

                if (i === 0) {
                    canvasCtx.moveTo(x, y);
                } else {
                    canvasCtx.lineTo(x, y);
                }

                x += sliceWidth;
            }

            canvasCtx.lineTo(canvas.width, canvas.height / 2);
            canvasCtx.stroke();
        }

        // Set up inactivity detection
        const activityEvents = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];

        const handleUserActivity = () => {
            resetInactivityTimer();
        };

        activityEvents.forEach(event => {
            window.addEventListener(event, handleUserActivity);
        });

        // Resize canvas and initialize audio visualization
        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();
        initializeAudio();
        resetInactivityTimer(); // Start the inactivity timer when the component mounts

        // Cleanup on unmount
        return () => {
            window.removeEventListener('resize', resizeCanvas);
            activityEvents.forEach(event => {
                window.removeEventListener(event, handleUserActivity);
            });
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
            if (inactivityTimerRef.current) {
                clearTimeout(inactivityTimerRef.current);
            }
        };
    }, [isDevelopment]);

    return (
        <div className="app-content">
            <header className="App-header">
                <img src={hero} className="hero-img" alt="Justin" />

                {/* Add the canvas element for audio visualization */}
                <canvas ref={canvasRef} id="audioCanvas"></canvas>

                <div className="auth-info">
                    <p>Signed in as {userName}</p>
                </div>
                <div className="chat-container">
                    <div className="chat-popup">
                        <div className="response-container">
                            <Chatbot setFilterCriteria={(criteria) => console.log(criteria)} />
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}

function App() {
    return (
        <Router>
            <AppContent />
        </Router>
    );
}

export default App;
