// src/Notification.js

import React from 'react';

const Notification = ({ message }) => (
    <div className={`notification ${message.toLowerCase().replace(/\s/g, '-')}`}>
        {message}
    </div>
);

export default Notification;
