import React, { useState, useEffect, useRef } from 'react';

const WebcamComponent = ({ onPersonDetected }) => {
    const videoRef = useRef(null);
    const [isPersonDetected, setIsPersonDetected] = useState(false);
    const detectionInterval = useRef(null);

    useEffect(() => {
        let stream = null;

        // Set up the webcam feed
        const setupWebcam = async () => {
            try {
                stream = await navigator.mediaDevices.getUserMedia({ video: true });
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            } catch (error) {
                console.error("Error accessing webcam:", error);
            }
        };

        setupWebcam();

        // Clean up the stream on component unmount
        return () => {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
        };
    }, []);

    useEffect(() => {
        const detectPerson = async () => {
            if (!videoRef.current) return;

            // Verify if environment variables are correctly set
            if (!process.env.REACT_APP_AZURE_FACE_API_ENDPOINT || !process.env.REACT_APP_AZURE_FACE_API_KEY) {
                console.error("Azure Face API Endpoint or Subscription Key is not set correctly. Check your .env file.");
                return;
            }

            try {
                // Create canvas from video to capture an image
                const canvas = document.createElement("canvas");
                canvas.width = videoRef.current.videoWidth;
                canvas.height = videoRef.current.videoHeight;
                const context = canvas.getContext("2d");
                context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

                // Get image as a Blob
                const blob = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg"));

                // Send blob to Azure Face API for face detection
                const endpoint = "https://eastus.api.cognitive.microsoft.com/face/v1.0/detect?returnFaceId=false&returnFaceLandmarks=false&detectionModel=detection_01";
                const subscriptionKey = "8b13b1f4e1294cd1bd4444f9c3ffc00f";

                const response = await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/octet-stream',
                        'Ocp-Apim-Subscription-Key': subscriptionKey,
                    },
                    body: blob,
                });

                if (response.ok) {
                    const data = await response.json();
                    const personDetected = data.length > 0;
                    setIsPersonDetected(personDetected);
                    if (onPersonDetected) {
                        onPersonDetected(personDetected);
                    }
                } else {
                    if (response.status === 401) {
                        console.error("Unauthorized request. Check if the subscription key is correct and matches the API region.");
                    } else {
                        console.error("Error detecting face:", response.status, response.statusText);
                    }
                }
            } catch (error) {
                console.error("Error detecting face:", error);
            }
        };

        // Set up interval to detect person every 5 seconds
        detectionInterval.current = setInterval(detectPerson, 5000);

        // Clean up the interval on component unmount
        return () => {
            if (detectionInterval.current) {
                clearInterval(detectionInterval.current);
            }
        };
    }, [onPersonDetected]);

    return (
        <div className="webcam-container">
            <video ref={videoRef} autoPlay muted style={{ width: '100%', maxWidth: '400px' }} />
            {isPersonDetected && <div className="person-detected-indicator">Person Detected!</div>}
        </div>
    );
};

export default WebcamComponent;
