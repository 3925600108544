import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';
import { throttle } from 'lodash';  // Import throttle from lodash

const SpeechControls = ({
                            listening,
                            startListening,
                            stopListening,
                            speechRecognitionSupported,
                            isSpeaking,  // Accept isSpeaking as a prop
                            isLoading,  // Accept isLoading as a prop
                        }) => {
    // Throttling button actions to prevent rapid clicks
    const throttledStartStopListening = throttle(() => {
        if (listening) {
            stopListening();
        } else {
            startListening();
        }
    }, 10000); // Limit to once every 10 seconds

    // Ref to the button element
    const listenButtonRef = useRef(null);

    // Set focus to the Listen button when the component mounts
    useEffect(() => {
        if (listenButtonRef.current) {
            listenButtonRef.current.focus();
        }
    }, []);

    // Set focus to the Listen button after Justin finishes speaking
    useEffect(() => {
        if (!isSpeaking && listenButtonRef.current) {
            listenButtonRef.current.focus();
        }
    }, [isSpeaking]);

    return (
        <div className="speech-controls-container">
            <div className="speech-controls">
                {speechRecognitionSupported && (
                    <button
                        ref={listenButtonRef}  // Assign the ref to the button
                        className="speech-button large-button"
                        onClick={throttledStartStopListening}
                        disabled={isSpeaking || isLoading}  // Disable the button when Justin is speaking or generating
                        tabIndex="0"  // Set tabIndex to make it focusable
                    >
                        <FontAwesomeIcon icon={listening ? faMicrophoneSlash : faMicrophone} />
                        {listening ? " Stop Listening" : " Listen"}
                    </button>
                )}
                {isLoading && (
                    <div className="button-overlay">
                        <div className="overlay-text">Generating Response...</div>
                    </div>
                )}
            </div>
            {isSpeaking && (
                <div className="button-overlay">
                    <div className="overlay-text">Generating_Response</div>
                </div>
            )}
        </div>
    );
};

export default SpeechControls;
