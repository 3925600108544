// src/LoadingOverlay.js

import React from 'react';

const LoadingOverlay = ({ isLoading }) => {
    return isLoading ? (
        <div className="loading-overlay visible">
            <div className="loading-indicator">Generating Response...</div>
        </div>
    ) : null;
};

export default LoadingOverlay;
