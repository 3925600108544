import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Chatbot.css';
import chatConfig from './ChatSetupJustin.json';
import { saveAs } from 'file-saver';
import useSpeechRecognition from './useSpeechRecognition';
import useSpeechSynthesis from './useSpeechSynthesis';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';
import SpeechControls from './SpeechControls';
import LoadingOverlay from './LoadingOverlay';
import Notification from './Notification';
import DragAndDropArea from './DragAndDropArea';
import WebcamComponent from './WebcamComponent';
import { performSearch } from './SearchService';

const Chatbot = ({ setFilterCriteria }) => {
    const [prompt, setPrompt] = useState('');
    const [response, setResponse] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [chunks, setChunks] = useState([]);
    const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
    const [notification, setNotification] = useState('');
    const [fileContent, setFileContent] = useState('');
    const [fileName, setFileName] = useState('');
    const [editableQA, setEditableQA] = useState({ question: '', answer: '' });
    const [isEditMode, setIsEditMode] = useState(false);
    const responseEndRef = useRef(null);
    const chatPopupRef = useRef(null);
    const [dragOver, setDragOver] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [isVisionEnabled, setIsVisionEnabled] = useState(false);
    const [hasGreeted, setHasGreeted] = useState(false);
    const [backgroundListening, setBackgroundListening] = useState(false);
    const [isSpeakingEnabled, setIsSpeakingEnabled] = useState(true);
    const [isSearchEnabled, setIsSearchEnabled] = useState(true); // Add missing isSearchEnabled state

    // Speech Recognition Hook
    const {
        listening,
        startListening,
        stopListening,
        supported: speechRecognitionSupported,
    } = useSpeechRecognition(handleVoiceInput);

    // Speech Synthesis Hook
    const { speak, isSpeaking } = useSpeechSynthesis();

    // Ref for the "Listen" button
    const listenButtonRef = useRef(null);

    // Handle voice input and set it as prompt
    function handleVoiceInput(transcribedText) {
        setPrompt(transcribedText);
        handleSubmit(null, transcribedText);
    }

    const handleInputChange = (e) => {
        setPrompt(e.target.value);
    };

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    // Function to handle detection from webcam
    const handlePersonDetected = (detected) => {
        if (detected && !hasGreeted && isVisionEnabled) {
            setNotification('Person detected');
            setTimeout(() => setNotification(''), 3000); // Clear notification after 3 seconds

            // Set speaking state to true to show "Justin is speaking" notification
            setIsSpeakingEnabled(true);

            // Make Justin greet the detected person
            speak("Hi, I'm Justin. You can press the Listen button when you want me to hear you.", () => {
                setIsSpeakingEnabled(false); // Once greeting is done, update speaking state
            });

            setHasGreeted(true); // Mark as greeted
            setIsVisionEnabled(false); // Disable vision to avoid repeated detection
            setIsOpen(false); // Hide the chatbox after greeting
        }
    };

    useEffect(() => {
        if (!isVisionEnabled) {
            setHasGreeted(false); // Reset greeting when vision is off
        }
    }, [isVisionEnabled]);

    useEffect(() => {
        responseEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [response]);

    // Speak the latest response when it changes
    useEffect(() => {
        if (response.length > 0 && isSpeakingEnabled) {
            const latestResponse = response[response.length - 1].answer;

            setNotification('Justin is speaking...');
            speak(latestResponse, () => {
                setIsSpeakingEnabled(false);
            });

            setTimeout(() => {
                setNotification('');
            }, 5000);
        }
    }, [response, speak, isSpeakingEnabled]);

    // Add keydown event listener to trigger listening on spacebar press or focus on Tab
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.code === 'Space' && listenButtonRef.current === document.activeElement && !listening) {
                startListening();
            } else if (event.code === 'Tab') {
                event.preventDefault();
                listenButtonRef.current?.focus();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [startListening, listening]);

    // Effect to manage background listening for "Hey Justin"
    useEffect(() => {
        if (speechRecognitionSupported) {
            const wakeWordListener = async () => {
                try {
                    const wakeWord = "hey justin";
                    if (!backgroundListening) {
                        setBackgroundListening(true);
                        startListening({
                            continuous: true,
                            onResult: (transcription) => {
                                if (transcription.toLowerCase().includes(wakeWord)) {
                                    stopListening();
                                    startListening();
                                    setNotification('Hey Justin detected, listening now...');
                                    setTimeout(() => setNotification(''), 3000);
                                }
                            }
                        });
                    }
                } catch (error) {
                    console.error('Error with background listening:', error);
                }
            };
            wakeWordListener();
        }
    }, [speechRecognitionSupported, startListening, stopListening, backgroundListening]);

    const handleSubmit = async (e, promptText = null) => {
        if (e && e.preventDefault) e.preventDefault();
        const currentPrompt = promptText || prompt;
        if (!currentPrompt.trim()) return;

        setIsLoading(true);

        try {
            let combinedResults = '';

            if (isSearchEnabled) {
                combinedResults = await performSearch(currentPrompt, 5);
            }

            if (isSearchEnabled) {
                const maxLength = 500;
                const newChunks = splitStringIntoChunks(combinedResults, maxLength);
                setChunks(newChunks);
                setCurrentChunkIndex(0);
                await processBatch(newChunks.slice(0, 3), currentPrompt);
            } else {
                await processBatch([], currentPrompt);
            }
        } catch (error) {
            console.error('Error performing search or processing prompt:', error);
            alert(`Error: ${error.message}`);
        }

        setIsLoading(false);
        setPrompt('');
    };

    const splitStringIntoChunks = (string, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < string.length; i += chunkSize) {
            chunks.push(string.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const processBatch = async (batchChunks, initialPrompt) => {
        const apiEndpoint = `${process.env.REACT_APP_OPENAI_ENDPOINT}/openai/deployments/${process.env.REACT_APP_OPENAI_DEPLOYMENT}/chat/completions?api-version=2023-03-15-preview`;
        const headers = {
            'api-key': process.env.REACT_APP_OPENAI_API_KEY,
            'Content-Type': 'application/json',
        };

        const pastMessages = response
            .slice(-chatConfig.chatParameters.pastMessagesToInclude)
            .map((msg) => [
                { role: 'user', content: msg.question },
                { role: 'assistant', content: msg.answer },
            ])
            .flat();

        const systemMessage = {
            role: 'system',
            content: 'You are Justin, and you are helping Karen Kilroy, software engineer and AI people-oid designer, by speaking to visitors at the Northwest Arkansas Tech Summit on Tuesday, October 29, 2024, with the goal of asking people to leave their information with Karen for a consultation. People can visit nyx.baby. They can find colorful photos around the conference and have a chance at a prize, contact us if it says WINNER on the back. Do not pronounce * since you are speaking, not writing. When a visitor approaches, you will greet them in a warm and friendly manner, with the focus on them. If they are curious about nocode AI and then let them answer. NoCode AI from our standpoint is always prompt-driven. Our nocode AI product is Nyx NoCode that lets you create entire web pages from scratch just by asking, without needing to drag and drop or code anything. You can quote your wife Harmony now and then and say how much you love Northwest Arkansas.' +
                'We live in a web-based world. Yet, the tools that are supposed to be easy to use really aren’t, and we spend all our time learning them and looking through templates, leaving little time for creativity.' +
                'This is why Karen has created Nyx NoCode. Imagine creating entire web pages from scratch just by asking through a text prompt. If that’s not easy enough, you can even create your prompt by doodling! With Nyx NoCode’s new doodle-pad interface, you can start drawing something and Nyx will begin guessing at what you’ve drawn and create the prompt for you. This is especially great for less-verbal users, like kids.' +
                'The nocode market is already huge—it’s currently valued at 4.3 billion dollars and is expected to grow to over 80 billion dollars in just five years.' +
                'It’s a Nyx NoCode November in Arkansas, starting November 1 with the Mena School District NoCode Hackathon kickoff which runs until Thanksgiving break. There will be 200 middle school and high school students competing for the web creations about their new mountain bike park during class time for credit. It’s amazing hands-on experience supported by big names like Walmart, AWS, Startup Junkie/ARISE, Nyx NoCode itself, LockStop.' +
                'Nyx NoCode has a gallery for all the files that you use in your creations, and for storing and sharing your creations themselves.' +
                'Nyx NoCode’s prompt library saves your own prompts in an easy-to-recall text format. This helps kickstart your creativity or streamline your workflow by reusing prompts you’ve created before.' +
                'Nyx NoCode is platform agnostic—it can integrate seamlessly with internal AI systems used by large companies like Walmart just by changing the application settings.' +
                'Karen was selected to move here from Austin, Texas through the Northwest Arkansas Life Works Here competition and stayed because of the wonderful tech community and natural beauty here. She has put her 30 years of web development experience into creating Nyx NoCode. She has authored several O’Reilly books on AI and technology and won IBM Watson Build for RILEY in 2017—an app that helps people who are blind and visually impaired get an explanation of the world around them.' +
                'Karen is also a software engineer with a leadership role as co-chair of the AI/ML task force within the Coalition of Content Provenance and Authenticity—a Silicon Valley-based organization focused on technology standards for content authenticity. This gives us a strong tie to Silicon Valley technology standards.' +
                'Nyx NoCode was named after Nyx—the Greek goddess associated with night—symbolizing how the tool brings clarity out of chaos.' +
                'If you’re interested in exploring more or even investing early in this groundbreaking technology—because yes it’s still early days—you should definitely talk with Karen directly after this presentation.' +
                'For those attending the Northwest Arkansas Tech Summit today, keep an eye out for colorful pictures around the conference; there might be special prizes hidden on their backs!' +
                'To get involved in Nyx NoCode November: You can sign up now by contacting Karen directly—she can take payment right away ($25 per month per user) and set you up on November 1! They also offer special pricing for multi-user or enterprise implementations. Another way to participate is by sponsoring the Mena Hackathon event—you could provide additional prizes for kids and classrooms!' +
                'To summarize: I’ve introduced you to Nyx NoCode—a revolutionary nocode platform that lets you create everything from web pages to games using simple prompts or doodles; told you about Nyx NoCode November, which starts with the Mena School District Hackathon; invited you to sign up for NYX NoCode at $25/month.' +
                'Thank you so much for your attention! I’ll be around the conference this afternoon to answer questions and show demonstrations if you’d like more details on anything we’ve discussed today.' +
                'Now I’ll hand it over to Justin who can help answer any immediate questions you might have.' +
                'For more a demo or to get started, they can either speak with Karen at the conference, text her at 330-289-1351, email at karen@nyx.baby, or visit her LinkedIn at https://linkedin.com/in/karenkilroy .  NYX website is at https://nyx.baby ' +
                'Nyx NoCode will be available on November 1, 2024 and people who are interested can sign up now, $25 a month for individuals and special prices for enterprises.'

        };

        const searchContextMessages = isSearchEnabled && batchChunks.length > 0
            ? batchChunks.map((chunk, index) => ({
                role: 'system',
                content: `Context Chunk ${index + 1}: ${chunk}`,
            }))
            : [];

        const messages = [
            systemMessage,
            ...pastMessages,
            ...searchContextMessages,
            { role: 'user', content: initialPrompt },
        ];

        const data = {
            model: 'gpt-4',
            messages: messages,
            max_tokens: 2048 - messages.length * 20,
            temperature: chatConfig.chatParameters.temperature,
            top_p: chatConfig.chatParameters.top_p,
            frequency_penalty: chatConfig.chatParameters.frequencyPenalty,
            presence_penalty: chatConfig.chatParameters.presencePenalty,
        };

        try {
            const apiResponse = await axios.post(apiEndpoint, data, { headers });
            const gptResponse = apiResponse.data.choices[0].message.content;
            setResponse((prevResponses) => [
                ...prevResponses,
                { question: initialPrompt, answer: gptResponse },
            ]);
            interpretAndActOnGPTResponse(gptResponse);
        } catch (error) {
            console.error('Error with OpenAI Chat:', error.response ? error.response.data : error.message);
            alert(`Error: ${error.response ? JSON.stringify(error.response.data) : error.message}`);
        }
    };

    const interpretAndActOnGPTResponse = (gptResponse) => {
        const lowerCaseResponse = gptResponse.toLowerCase();

        if (lowerCaseResponse.includes('image') || lowerCaseResponse.includes('photo')) {
            setFilterCriteria({ type: 'image' });
        } else if (lowerCaseResponse.includes('audio') || lowerCaseResponse.includes('music')) {
            setFilterCriteria({ type: 'audio' });
        } else if (lowerCaseResponse.includes('document')) {
            setFilterCriteria({ type: 'document' });
        } else if (lowerCaseResponse.includes('video')) {
            setFilterCriteria({ type: 'video' });
        } else if (lowerCaseResponse.includes('code') || lowerCaseResponse.includes('file')) {
            setFileContent(gptResponse);
            setFileName(`Justin_File_${Date.now()}.txt`);
            setNotification('File ready for download');
            setTimeout(() => setNotification(''), 3000);
        } else {
            setFilterCriteria({});
        }
    };

    const handleClearChat = () => {
        setResponse([]);
        setPrompt('');
        setChunks([]);
        setCurrentChunkIndex(0);
    };

    const toggleMute = () => {
        setIsMuted((prevMuted) => !prevMuted);
        setIsSpeakingEnabled((prev) => !prev);
    };

    const readAndProcessFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const fileContent = event.target.result;
                await processBatch([fileContent], file.name);
                resolve();
            };
            reader.onerror = reject;
            reader.readAsText(file);
        });
    };

    return (
        <div className="chat-container">
            <button className="chat-toggle-button" onClick={toggleChat} >
                {isOpen ? 'Hide Ask JUSTIN' : 'Questions? Ask JUSTIN'}
            </button>
            <SpeechControls
                listening={listening}
                startListening={startListening}
                stopListening={stopListening}
                speechRecognitionSupported={speechRecognitionSupported}
                isSpeaking={isSpeaking}
            />
            {isOpen && (
                <DragAndDropArea
                    dragOver={dragOver}
                    handleDragOver={(e) => { e.preventDefault(); setDragOver(true); }}
                    handleDragLeave={() => setDragOver(false)}
                    handleDrop={async (e) => {
                        e.preventDefault();
                        setDragOver(false);
                        const files = e.dataTransfer.files;
                        if (files.length > 0) {
                            for (const file of files) {
                                await readAndProcessFile(file);
                            }
                        }
                    }}
                    chatPopupRef={chatPopupRef}
                >
                    <div className="chatbox-container">
                        <div className="chat-title-bar">
                            <span>Justin Kilroy</span>
                            <div className="toggles-container">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        checked={isSearchEnabled}
                                        onChange={() => setIsSearchEnabled(!isSearchEnabled)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                                <span className="toggle-label">Search</span>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        checked={isVisionEnabled}
                                        onChange={() => setIsVisionEnabled(!isVisionEnabled)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                                <span className="toggle-label">Vision</span>
                            </div>
                        </div>

                        {isVisionEnabled && (
                            <WebcamComponent onPersonDetected={handlePersonDetected} />
                        )}

                        {listening && <div className="listening-indicator">Listening...</div>}
                        <LoadingOverlay isLoading={isLoading} />

                        {/* Mute/Unmute Button inside the chatbox */}
                        <button
                            className="mute-button"
                            onClick={toggleMute}
                        >
                            {isMuted ? "Unmute Justin" : "Mute Justin"}
                        </button>

                        {/* Listen Button */}
                        <button
                            ref={listenButtonRef}
                            onClick={startListening}
                            className="listen-button"
                        >
                            Listen
                        </button>

                        <div className="response-container">
                            {response.map((exchange, index) => (
                                <ChatMessage
                                    key={index}
                                    exchange={exchange}
                                    isEditMode={isEditMode}
                                    editableQA={editableQA}
                                    handleEditQA={(qa) => {
                                        setEditableQA(qa);
                                        setIsEditMode(true);
                                    }}
                                    handleQAChange={(e) => {
                                        const { name, value } = e.target;
                                        setEditableQA((prevQA) => ({
                                            ...prevQA,
                                            [name]: value,
                                        }));
                                    }}
                                    handleSaveEditedQA={() => {
                                        setResponse((prevResponses) =>
                                            prevResponses.map((qa) =>
                                                qa.question === editableQA.question ? editableQA : qa
                                            )
                                        );
                                        setIsEditMode(false);
                                        setNotification('Response Edited');
                                        setTimeout(() => setNotification(''), 3000);
                                    }}
                                />
                            ))}
                            <div ref={responseEndRef} />
                        </div>
                        <ChatInput
                            prompt={prompt}
                            handleInputChange={handleInputChange}
                            handleSubmit={handleSubmit}
                            handleClearChat={handleClearChat}
                            handleSaveChat={() => alert('Contact my designer, karen@kilroyai.com, to enable Memories.')}
                            handleDownloadMemories={() => alert('Contact my designer, karen@kilroyai.com, to enable Memories.')}
                            handleNextChunk={async () => {
                                if (currentChunkIndex < chunks.length - 1) {
                                    const nextChunkIndex = currentChunkIndex + 1;
                                    setCurrentChunkIndex(nextChunkIndex);
                                    await processBatch(chunks.slice(nextChunkIndex, nextChunkIndex + 1), '');
                                }
                            }}
                            currentChunkIndex={currentChunkIndex}
                            chunks={chunks}
                            fileContent={fileContent}
                            fileName={fileName}
                            saveGeneratedFile={() => {
                                const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8' });
                                saveAs(blob, fileName);
                                setNotification(`File saved: ${fileName}`);
                                setTimeout(() => setNotification(''), 3000);
                            }}
                        />
                        {isSpeaking && (
                            <div className="speaking-indicator">Generating Response...</div>
                        )}
                    </div>
                </DragAndDropArea>
            )}
            {notification && <Notification message={notification} />}
        </div>
    );
};

export default Chatbot;
