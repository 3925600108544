// src/DragAndDropArea.js

import React from 'react';

const DragAndDropArea = ({
                             dragOver,
                             handleDragOver,
                             handleDragLeave,
                             handleDrop,
                             chatPopupRef,
                             children,
                         }) => (
    <div
        className={`chat-popup ${dragOver ? 'drag-over' : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        ref={chatPopupRef}
    >
        {children}
    </div>
);

export default DragAndDropArea;
