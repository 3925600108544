// src/ChatInput.js

import React from 'react';

const ChatInput = ({
                       prompt,
                       handleInputChange,
                       handleSubmit,
                       handleClearChat,
                       handleCopyChat,
                       handleSaveChat,
                       handleDownloadMemories,
                       handleNextChunk,
                       currentChunkIndex,
                       chunks,
                       fileContent,
                       fileName,
                       saveGeneratedFile,
                   }) => {
    return (
        <form onSubmit={handleSubmit}>
      <textarea
          value={prompt}
          onChange={handleInputChange}
          placeholder="Hi, Guest"
          rows="4"
          style={{
              width: 'calc(100% - 20px)',
              resize: 'both',
              maxHeight: 'calc(20 * 1.5em)',
          }}
      />
            <div className="button-container">
                <button tabIndex="0" type="submit" title="Send to JUSTIN">
                    Send
                </button>
                <button type="button" onClick={handleClearChat} title="Clear Chat">
                    Clear
                </button>
                <button type="button" onClick={handleCopyChat} title="Copy Chat">
                    Copy
                </button>
                <button type="button" onClick={handleSaveChat} title="Remember">
                    Remember
                </button>
                <button
                    type="button"
                    onClick={handleDownloadMemories}
                    title="Download Memories"
                >
                    Download Memories
                </button>
                {currentChunkIndex < chunks.length - 1 && (
                    <button type="button" onClick={handleNextChunk} title="Next Chunk">
                        Next Chunk
                    </button>
                )}
                {fileContent && (
                    <button
                        type="button"
                        onClick={saveGeneratedFile}
                        title="Download Generated File"
                    >
                        Download {fileName}
                    </button>
                )}
            </div>
        </form>
    );
};

export default ChatInput;
