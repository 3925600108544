// src/useSpeechRecognition.js

import { useState, useEffect, useRef } from 'react';

const useSpeechRecognition = (onResult) => {
    const [listening, setListening] = useState(false);
    const [supported, setSupported] = useState(true);
    const recognitionRef = useRef(null);

    useEffect(() => {
        if (typeof window === 'undefined') {
            setSupported(false);
            return;
        }

        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
            console.error('Browser does not support speech recognition.');
            setSupported(false);
            return;
        }

        const recognition = new SpeechRecognition();
        recognitionRef.current = recognition;
    }, []);

    useEffect(() => {
        const recognition = recognitionRef.current;
        if (!recognition) return;

        recognition.continuous = false; // Set to true if you want continuous recognition
        recognition.interimResults = false;
        recognition.lang = 'en-US';

        const handleResult = (event) => {
            const transcript = Array.from(event.results)
                .map(result => result[0])
                .map(result => result.transcript)
                .join('');
            onResult(transcript);
            setListening(false);
        };

        const handleError = (event) => {
            console.error('Speech recognition error:', event.error);
            setListening(false);
        };

        const handleEnd = () => {
            setListening(false);
        };

        if (listening) {
            recognition.addEventListener('result', handleResult);
            recognition.addEventListener('error', handleError);
            recognition.addEventListener('end', handleEnd);

            recognition.start();
        } else {
            recognition.stop();
        }

        return () => {
            recognition.removeEventListener('result', handleResult);
            recognition.removeEventListener('error', handleError);
            recognition.removeEventListener('end', handleEnd);
            recognition.stop();
        };
    }, [listening, onResult]);

    const startListening = () => setListening(true);
    const stopListening = () => setListening(false);

    return { listening, startListening, stopListening, supported };
};

export default useSpeechRecognition;
