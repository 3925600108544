// src/useSpeechSynthesis.js
import { useCallback, useState, useRef } from 'react';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

const useSpeechSynthesis = () => {
    const [isSpeaking, setIsSpeaking] = useState(false);
    const synthesizerRef = useRef(null);

    const speak = useCallback((text) => {
        if (text !== '') {
            // Stop any existing speech
            if (synthesizerRef.current) {
                synthesizerRef.current.close();
                synthesizerRef.current = null;
                setIsSpeaking(false);
            }

            setIsSpeaking(true);

            const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
                process.env.REACT_APP_AZURE_TTS_KEY,
                process.env.REACT_APP_AZURE_TTS_REGION
            );

            speechConfig.speechSynthesisVoiceName = 'en-US-AndrewMultilingualNeural';

            const audioConfig = SpeechSDK.AudioConfig.fromDefaultSpeakerOutput();

            const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);
            synthesizerRef.current = synthesizer;

            synthesizer.speakTextAsync(
                text,
                (result) => {
                    if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
                        console.log('Speech synthesized successfully.');
                    } else if (result.reason === SpeechSDK.ResultReason.Canceled) {
                        const cancellationDetails = SpeechSDK.CancellationDetails.fromResult(result);
                        console.error('Speech synthesis canceled:', cancellationDetails.reason);
                        if (cancellationDetails.reason === SpeechSDK.CancellationReason.Error) {
                            console.error('Error details:', cancellationDetails.errorDetails);
                        }
                    } else {
                        console.error('Speech synthesis failed.', result.errorDetails);
                    }
                    synthesizer.close();
                    synthesizerRef.current = null;
                    setIsSpeaking(false);
                },
                (error) => {
                    console.error('Error during speech synthesis:', error);
                    synthesizer.close();
                    synthesizerRef.current = null;
                    setIsSpeaking(false);
                }
            );
        }
    }, []); // No dependencies

    return { speak, isSpeaking };
};

export default useSpeechSynthesis;
