// src/SearchService.js

import { SearchClient, AzureKeyCredential } from "@azure/search-documents";

// Azure Search configuration
const searchServiceName = process.env.REACT_APP_SEARCH_SERVICE;
const indexName1 = process.env.REACT_APP_SEARCH_INDEX;
const indexName2 = process.env.REACT_APP_SEARCH_INDEX2; // Name of the second index
const searchApiKey = process.env.REACT_APP_AZURE_SEARCH_API_KEY;
const searchEndpoint = `https://${searchServiceName}.search.windows.net`;

// Initialize Search Clients
const searchClient1 = new SearchClient(
    searchEndpoint,
    indexName1,
    new AzureKeyCredential(searchApiKey)
);

const searchClient2 = new SearchClient(
    searchEndpoint,
    indexName2,
    new AzureKeyCredential(searchApiKey)
);

/**
 * Performs a search query across multiple indexes.
 * @param {string} query - The search query.
 * @param {number} top - Number of top results to retrieve from each index.
 * @returns {Promise<string>} - Combined search results from all indexes.
 */
export const performSearch = async (query, top = 5) => {
    let combinedResults = "";

    try {
        // Search in the first index
        const searchResponse1 = await searchClient1.search(query, { top });
        for await (const result of searchResponse1.results) {
            combinedResults += result.document.content + " ";
        }

        // Search in the second index
        const searchResponse2 = await searchClient2.search(query, { top });
        for await (const result of searchResponse2.results) {
            combinedResults += result.document.content + " ";
        }
    } catch (error) {
        console.error("Error performing search:", error);
        throw error; // Rethrow to be handled by the caller
    }

    return combinedResults;
};
